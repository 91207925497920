import { FORM_ERROR } from "final-form";
import { extractErrorMessage, extractFieldErrorMessage } from "@mnemonic/redux-utils";
import { getAvailableMethods } from "../Authenticate/utils";
export const preferredAuthenticationMethods = ["SIGNATURE", "TOTP"];
/**
 * Maps backend errors to form fields.
 *
 * Extracts error messages for specified fields, where field names must match the backend's error keys.
 * If no field-specific errors are found, a general form error is returned.
 *
 * @param {unknown} e - The error object from the backend.
 * @param {string[]} fields - Array of field names, matching the backend's error keys.
 * @returns {Record<string, string | null> | { FORM_ERROR: string }}
 *          - Field-specific errors or a general form error.
 *
 * @note The field names provided in the `fields` array must exactly match the error keys returned by the backend.
 * If they don't, no field-specific errors will be mapped, and the general form error will be returned instead.
 */
export const mapErrors = (e, fields) => {
    const supportedFieldErrors = fields.reduce((acc, field) => {
        acc[field] = extractFieldErrorMessage(e, field);
        return acc;
    }, {});
    if (Object.entries(supportedFieldErrors).some(([, value]) => Boolean(value))) {
        return supportedFieldErrors;
    }
    return { [FORM_ERROR]: extractErrorMessage(e) || JSON.stringify(e, null, 2) };
};
/**
 * Returns the user's authentication methods that are enabled but not initialized.
 *
 * @param {IAuthenticationMethod[]} methods - List of authentication methods to check.
 * @returns {IAuthenticationMethod[]} - Returns the authentication methods that are enabled but uninitialized.
 *
 * @example
 * const methods = [
 *   { method: 'TOTP', enabled: true, initialized: false, ...methodProps },
 *   { method: 'SIGNATURE', enabled: true, initialized: true, ...methodProps },
 *   { method: 'SMS', enabled: true, initialized: true, ...methodProps },
 * ];
 *
 * const result = getAvailableUnConfiguredAuthMethods(methods, enrolledKeys); // returns [
 *    { method: 'TOTP', enabled: true; initialized: false, ...methodProps },
 * ]
 */
export const getUnConfiguredAuthMethods = (methods) => {
    return methods?.filter((method) => method.enabled && !method.initialized);
};
/**
 * Returns preferred authentication methods that are enabled but not initialized.
 * Currently, TOTP and WebAuthn are preferred.
 * Will return SIGNATURE regardless of whether it's initialized as long as the user has no WebAuthn keys enrolled.
 *
 * @param {IAuthenticationMethod[]} methods - List of authentication methods to check.
 * @param {IPublicKeyInfo[]} enrolledKeys - List of user's public keys. Used to determine whether they have a WebAuthn key enrolled
 * @returns {IAuthenticationMethod[]} - Returns the authentication methods we want to recommend the user to set up.
 *
 * @example
 * const methods = [
 *   { method: 'TOTP', enabled: true, initialized: false, ...methodProps },
 *   { method: 'SIGNATURE', enabled: true, initialized: true, ...methodProps },
 *   { method: 'SMS', enabled: true, initialized: true, ...methodProps },
 * ];
 * const enrolledKeys = [
 *    { keyID: 1, type: "iphone", ...keyProps }
 * ]
 *
 * const result = getUnConfiguredPreferredAuthMethods(methods, enrolledKeys); // returns [
 *    { method: 'TOTP', enabled: true; initialized: false, ...methodProps },
 *    { method: 'SIGNATURE', enabled: true; initialized: true, ...methodProps }
 * ]
 */
export const getUnConfiguredPreferredAuthMethods = (methods, enrolledKeys) => {
    const unConfiguredMethods = getUnConfiguredAuthMethods(methods).filter((method) => preferredAuthenticationMethods.includes(method.method) && method.method != "SIGNATURE");
    ////WebAuthn is a type of SIGNATURE. We need to be check whether the user has any WebAuthn keys enrolled.
    if (!preferredAuthenticationMethods.includes("SIGNATURE"))
        return unConfiguredMethods;
    //1: check if user has SIGNATURE enabled
    const enabledSignatureMethod = methods.find((method) => method.method == "SIGNATURE" && method.enabled);
    //2: check if user has existing WebAuthn keys
    const hasExistingWebAuthnKey = enrolledKeys.find((key) => key.type == "webauthn") != undefined;
    //2: If enabled and missing WebAuthn key, append as unConfiguredMethod.
    if (enabledSignatureMethod != undefined && !hasExistingWebAuthnKey)
        unConfiguredMethods.push(enabledSignatureMethod);
    return unConfiguredMethods;
};
export const createAuthenticationListItems = (availableAuthMethods) => {
    const availableGoodAuthenticationMethods = availableAuthMethods
        ?.filter(({ method, enabled }) => enabled && preferredAuthenticationMethods.includes(method))
        ?.map((item) => item.method);
    const listItemsWithModifiedLink = getAvailableMethods(availableGoodAuthenticationMethods)?.map((item) => ({
        ...item,
        to: "", //Blank link will not refresh or attempt to redirect. This lets us redirect users with RTK actions instead.
    }));
    return listItemsWithModifiedLink;
};
export const setSessionStorage = (method) => {
    const action = method === "TOTP" ? "totpInit" : "webauthn";
    const storage = window.sessionStorage;
    const stringifiedState = JSON.stringify({ action });
    storage.setItem("state", stringifiedState);
};
