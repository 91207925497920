import React, { useState } from "react";
import { Form } from "react-final-form";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "@mnemonic/intl";
import { TextField } from "../../Components/MuiFinalForm";
import { PasswordChecker } from "../../Components/PasswordChecker";
import { SubmitButton } from "../../Components/SubmitButton";
import { useFetchBreachStatusMutation, useUpdatePasswordMutation } from "../../Redux/Service/authenticateApi";
import { commonMessages } from "../../commonMessages";
import { validatePassword } from "../Login/utils/validate";
import { mapErrors } from "./utils";
export const StepDefinePassword = ({ enrollFlowState, setEnrollFlowState }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [definePassword] = useUpdatePasswordMutation();
    const [checkPassword] = useFetchBreachStatusMutation();
    const sessionKey = enrollFlowState?.setPasswordAuthorization?.sessionKey;
    const passwordAvailable = async (value) => {
        const passwordError = validatePassword(value);
        if (passwordError)
            return passwordError;
        const results = await checkPassword({ criteria: { password: value }, metaOpts: { sessionKey } }).unwrap();
        if (results?.data?.breachCount !== 0) {
            return (<FormattedMessage id="validation.breached.password" defaultMessage="This password has been breached. Please choose a different one."/>);
        }
        return undefined;
    };
    return (<Box>
      <Typography variant="body1">
        <FormattedMessage id="login.verify.definePassword.info" defaultMessage="Your user account has been verified. Please set a password to log in to your account"/>
      </Typography>
      <Form onSubmit={async ({ newPassword }) => {
            try {
                await definePassword({
                    criteria: { newPassword, authorization: enrollFlowState?.setPasswordAuthorization?.authorization },
                    metaOpts: { sessionKey },
                }).unwrap();
                return setEnrollFlowState({ ...enrollFlowState, step: { kind: "complete" } });
            }
            catch (e) {
                return mapErrors(e, ["password"]);
            }
        }} render={({ handleSubmit, submitError, values }) => {
            return (<form onSubmit={handleSubmit}>
              <Box sx={({ spacing }) => ({ mt: spacing(3) })}>
                <TextField InputProps={{
                    endAdornment: (<InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end" aria-label="toggle password visibility">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>),
                }} fullWidth label={<FormattedMessage {...commonMessages.SetPassword}/>} name="newPassword" type={showPassword ? "text" : "password"} validate={passwordAvailable}/>
                <PasswordChecker newPassword={values?.newPassword} sessionKey={sessionKey}/>
              </Box>
              {submitError && (<Alert variant="filled" severity="error" sx={{ mt: 2 }}>
                  {submitError}
                </Alert>)}
              <SubmitButton>
                <FormattedMessage {...commonMessages.SetPassword}/>
              </SubmitButton>
            </form>);
        }}/>
    </Box>);
};
