import React from "react";
import { useNavigate } from "react-router";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { FormattedMessage } from "@mnemonic/intl";
import * as ArgusUtils from "@mnemonic/pure-utils/ArgusUtils";
import AppStoreBadge from "../../../assets/AppStoreBadge.svg";
import GooglePlayBadge from "../../../assets/GooglePlayBadge.png";
export const ArgusMobileFooter = () => {
    const { domain } = ArgusUtils.getTargetMeta();
    const theme = useTheme();
    const navigate = useNavigate();
    // Should only be visible on the mnemonic portal
    if (domain === "MNEMONIC") {
        return (<Box sx={{ px: theme.spacing(6), py: theme.spacing(2), textAlign: "center" }}>
        <Divider />
        <Grid container justifyContent="center">
          <Box sx={{ mt: theme.spacing(2) }}>
            <Typography variant="h6">
              <FormattedMessage id="login.argus.mobile.footer.header" defaultMessage="Get Argus for Mobile"/>
            </Typography>
          </Box>
          <Box sx={{ mt: 1 }}>
            <Typography variant="body2">
              <FormattedMessage id="login.argus.mobile.footer.description" defaultMessage="Get push notifications and manage your cases with Argus for Mobile."/>
            </Typography>
          </Box>
          <Grid container justifyContent="center" sx={{ mt: theme.spacing(1) }}>
            <ButtonBase disableRipple onClick={() => navigate("/redirect/mobile-app")}>
              <img src={AppStoreBadge} style={{ marginRight: 8 }}/>
            </ButtonBase>
            <ButtonBase disableRipple onClick={() => navigate("/redirect/mobile-app")}>
              <img src={GooglePlayBadge} style={{ marginLeft: 8, height: 40 }}/>
            </ButtonBase>
          </Grid>
        </Grid>
      </Box>);
    }
    else {
        return null;
    }
};
