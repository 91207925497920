import React, { useState } from "react";
import { Form } from "react-final-form";
import { FormattedMessage, useIntl } from "@mnemonic/intl";
import * as ArgusUtils from "@mnemonic/pure-utils/ArgusUtils";
import { extractErrorMessage } from "@mnemonic/redux-utils";
import { prepareWebauthnAuthentication } from "@mne-authentication/api";
import { LoginBox, LoginBoxCallout, LoginBoxContent, LoginBoxHeader, LoginBoxSubHeader, } from "../../Components/Login/Layout";
import { LoginButtonWithDisclaimer } from "../../Components/LoginButtonWithDisclaimer";
import { PasswordField } from "../../Components/PasswordTextField";
import { UsernameTextField } from "../../Components/UsernameTextField";
import { useAuthenticateWebauthnMutation } from "../../Redux/Service/authenticateApi";
import { useTypedDispatch, useTypedSelector } from "../../Redux/createStore";
import { globalSlice } from "../../Redux/globalSlice";
import { commonMessages } from "../../commonMessages";
import RedirectWrapper from "./RedirectWrapper";
import { StyledSingleFieldWrapper } from "./StyledComponets";
import { saveLoginDetails } from "./utils/saveLoginDetails";
import { createAuthenticationRequest } from "./utils/webAuthRequestMappingUtils";
export const LoginWebAuthn = () => {
    const { lastUsername } = useTypedSelector((state) => state.global);
    const { formatMessage } = useIntl();
    const { apiUrl, domain } = ArgusUtils.getTargetMeta();
    const [authenticateWebautn, { isLoading }] = useAuthenticateWebauthnMutation();
    const [errorMessage, setErrorMessage] = useState(null);
    const dispatch = useTypedDispatch();
    return (<RedirectWrapper>
      <LoginBox loading={isLoading}>
        <LoginBoxHeader />
        <LoginBoxContent>
          {errorMessage && <LoginBoxCallout type="error">{errorMessage}</LoginBoxCallout>}
          <LoginBoxSubHeader method={<FormattedMessage id="login.method.webauthn" defaultMessage="WebAuthn"/>}/>
          <Form initialValues={{ username: lastUsername, password: null }} onSubmit={async (formValues, formApi) => {
            try {
                setErrorMessage(null);
                const { response, json } = await prepareWebauthnAuthentication({ userName: formValues.userName, password: formValues.password, domain }, { apiUrl });
                if (response.status === 200) {
                    const criteria = await createAuthenticationRequest({
                        webauthnAuthenticationResponse: json?.data,
                        userName: formValues.userName,
                        domain,
                    });
                    authenticateWebautn({ criteria })
                        .unwrap()
                        ?.then((payload) => {
                        const isSuccess = saveLoginDetails({
                            username: formValues.userName,
                            method: "signature",
                            payload,
                        });
                        dispatch(globalSlice.actions.setLoginSuccess(isSuccess));
                    })
                        // on error reset password field
                        ?.catch((error) => {
                        formApi.change("password", "");
                        setErrorMessage(extractErrorMessage(error));
                    });
                }
                else {
                    setErrorMessage(json.messages.length >= 1
                        ? json.messages[0].message
                        : formatMessage(commonMessages.AuthenticationFailed));
                }
            }
            catch (e) {
                console.error(e);
                setErrorMessage(formatMessage(commonMessages.UnknownError));
            }
        }} render={({ values, handleSubmit }) => {
            return (<form onSubmit={handleSubmit}>
                  <StyledSingleFieldWrapper>
                    <UsernameTextField name="userName" autoFocus={!values.userName}/>
                  </StyledSingleFieldWrapper>
                  <StyledSingleFieldWrapper>
                    <PasswordField name="password" autoFocus={Boolean(values.userName)} method="static"/>
                  </StyledSingleFieldWrapper>
                  <LoginButtonWithDisclaimer disableButton={isLoading}/>
                </form>);
        }}/>
        </LoginBoxContent>
      </LoginBox>
    </RedirectWrapper>);
};
