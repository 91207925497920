import * as ArgusUtils from "@mnemonic/pure-utils/ArgusUtils";
import { handleResponse } from "@mnemonic/redux-utils";
import { fetchAuthMethods, fetchAuthMethodsForUser, fetchAvailableAuthMethods, fetchEnrolledKeys, } from "@mne-authentication/api";
import { baseApi } from "./baseApi";
const methodsApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        fetchAvailableAuthMethod: build.query({
            queryFn: ({ metaOpts }) => handleResponse(fetchAvailableAuthMethods({ apiUrl: ArgusUtils.getApiUrl() }), metaOpts),
        }),
        fetchAuthenticationMethodsForUser: build.query({
            queryFn: ({ userId, metaOpts }) => handleResponse(fetchAuthMethodsForUser(userId, {
                apiUrl: ArgusUtils.getApiUrl(),
                sessionKey: metaOpts?.sessionKey,
            }), metaOpts),
        }),
        fetchAuthenticationMethodsForCurrentSession: build.query({
            queryFn: (metaOpts) => handleResponse(fetchAuthMethods({ apiUrl: ArgusUtils.getApiUrl() }), metaOpts),
        }),
        fetchSignatureKeys: build.query({
            queryFn: ({ criteria, metaOpts }) => {
                return handleResponse(fetchEnrolledKeys({ ...criteria }, { apiUrl: ArgusUtils.getApiUrl(), sessionKey: metaOpts?.sessionKey }));
            },
        }),
    }),
});
export const { useFetchAvailableAuthMethodQuery, useFetchSignatureKeysQuery, useFetchAuthenticationMethodsForUserQuery, useFetchAuthenticationMethodsForCurrentSessionQuery, } = methodsApi;
export default methodsApi;
