import React, { useState } from "react";
import { Form } from "react-final-form";
import { NavLink } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "@mnemonic/intl";
import Link from "@mnemonic/mui5/Link";
import ContactUsDialog from "../../Components/ContactUsDialog";
import { TextField } from "../../Components/MuiFinalForm";
import { SubmitButton } from "../../Components/SubmitButton";
import { useActivateEnrollmentMutation, useVerifyAndAuthorizeMutation } from "../../Redux/Service/authenticateApi";
import { commonMessages } from "../../commonMessages";
import { validateEmptyField } from "../Login/utils/validate";
import { mapErrors } from "./utils";
export const StepVerifySmsCode = ({ domain, enrollFlowState, setEnrollFlowState }) => {
    const [verifyAndAuthorize] = useVerifyAndAuthorizeMutation();
    const [activateEnrollment, { isError }] = useActivateEnrollmentMutation();
    const [open, setOpen] = useState(false);
    const userName = enrollFlowState.userName;
    const enrollmentCode = enrollFlowState.enrollmentCode;
    return (<Box>
      <ContactUsDialog {...{ open, onClose: () => setOpen(false) }}/>
      <Typography variant="body1">
        <FormattedMessage id="login.verify.verifySMS.info" defaultMessage="We have sent you an SMS code, please enter the code below to verify your user"/>
      </Typography>
      <Form onSubmit={async ({ verificationCode }) => {
            try {
                const data = await verifyAndAuthorize({
                    criteria: {
                        activationCookie: enrollFlowState.activationCookie,
                        userID: enrollFlowState.userId,
                        verificationCode,
                    },
                }).unwrap();
                const setPasswordAuthorization = data?.data?.authorizations?.find((item) => item.operation === "password.set");
                const enrollTOTPAuthorization = data?.data?.authorizations?.find((item) => item.operation === "totp.initialize");
                const enrollPublicKeyAuthorization = data?.data?.authorizations?.find((item) => item.operation === "publickey.enroll");
                return setEnrollFlowState({
                    ...enrollFlowState,
                    step: { kind: "definePassword" },
                    setPasswordAuthorization: {
                        authorization: setPasswordAuthorization.authorization,
                        expires: setPasswordAuthorization.expires,
                        sessionKey: data?.data?.sessionKey,
                    },
                    enrollTOTPAuthorization: enrollTOTPAuthorization.authorization,
                    enrollPublicKeyAuthorization: enrollPublicKeyAuthorization.authorization,
                });
            }
            catch (e) {
                return mapErrors(e, ["verificationCode"]);
            }
        }} render={({ handleSubmit, submitError }) => {
            return (<form onSubmit={handleSubmit}>
              <Box sx={({ spacing }) => ({ mt: spacing(3) })}>
                <TextField autoFocus fullWidth name="verificationCode" type="number" validate={(value) => validateEmptyField(value)} label={<FormattedMessage {...commonMessages.SMSVerificationCode}/>}/>
                <Typography variant="body2" mt={1} mb={2}>
                  <FormattedMessage id="login.verifySMS.missing" defaultMessage="Not gotten sms, or sms code expired? "/>
                  <Link component={NavLink} onClick={async () => {
                    try {
                        const { data } = await activateEnrollment({
                            criteria: { domain, userName, enrollmentCode },
                        }).unwrap();
                        setEnrollFlowState({
                            ...enrollFlowState,
                            activationCookie: data?.activationCookie,
                        });
                    }
                    catch (e) {
                        return console.error(e);
                    }
                }} sx={{ color: "inherit", marginRight: "4px" }}>
                    <FormattedMessage id="login.verifySMS.resend" defaultMessage="Resend SMS"/>
                  </Link>
                  <FormattedMessage id="login.verifySMS.or" defaultMessage="or"/>
                  <Link component={NavLink} onClick={() => setOpen(true)} sx={{ color: "inherit", marginLeft: "4px" }}>
                    <FormattedMessage {...commonMessages.ContactUs}/>
                  </Link>
                </Typography>
              </Box>
              {submitError && (<Alert variant="filled" severity="error" sx={({ palette }) => ({ background: palette.error.main })}>
                  {submitError}
                </Alert>)}
              {isError && (<Alert variant="filled" severity="error" sx={({ palette }) => ({ background: palette.error.main })}>
                  <Typography variant="body2">
                    <FormattedMessage id="login.verifySMS.error" defaultMessage="Could not send SMS. Please contact us so we can help sort out the issue"/>
                  </Typography>
                </Alert>)}
              <SubmitButton>
                <FormattedMessage id="login.verifySMS.button.submit" defaultMessage="verify sms code"/>
              </SubmitButton>
            </form>);
        }}/>
    </Box>);
};
